<template>
    <div class="topic" :class="{ 'is-pending' : topic.is_pending == TOPIC_STATUS.PENDING }">
        <router-link :to="{ name: 'web-app-topics', params: { topicSlug: topic.slug } }">
            {{ topic.name }}
        </router-link>
    </div>
</template>

<script>
import { TOPIC_STATUS } from "@/utils/constants";

export default {
    name: "Topic",
    props: {
        topic: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            TOPIC_STATUS
        };
    }
}
</script>

<style lang="scss" scoped>
.topic {
    a {
        height: 100%;
        display: block;
        color: white;
        background-color: #2C2C2E;
        width: fit-content;
        padding: 4px 10px;
        padding-right: 15px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        text-decoration: none;
    }

    &.is-pending {
        a {
            opacity: .5;
            pointer-events: none;
        }
    }
}
</style>
